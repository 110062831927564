import { surveyService } from "@services";
import { HTTP_CODE_OK } from "@constants";

export default {
    name: "SurveyCallback",
    methods: {
        callbackSurveyInitial: async function () {
            await surveyService.surveyCallback({
                key: this.$route.query.key,
            }).then((res) => {
                const data = res.data;
                if (data && res.status == HTTP_CODE_OK) {
                    this.$router.push({
                        name: "lottery.game",
                        params: {
                            campaignUrlName: data.campaign_url_name,
                            lotteryUuid: data.lottery_uuid,
                        },
                    }).catch(() => {});
                } else {
                    this.$router.push({
                        name: "campaign.error",
                        replace: true,
                    });
                }
            });
        },
    },
    mounted() {
        this.callbackSurveyInitial();
    },
};
